.sw-btn {
    display:inline;

    &_dummy {
        position:relative;
        display:inline-block;
        height:auto;
        margin:0;
        padding:0;

        color:inherit;
        background:none;
        border:0;
        border-radius:0;
        outline:none !important;

        font-family:inherit;
        font-size:inherit;
        font-weight:inherit;
        letter-spacing:inherit;
        line-height:inherit;
        text-align:inherit;
        text-transform:inherit;
        text-indent:inherit;

        &:hover, &:focus {
            text-decoration:none;
        }
    }

    &_cta {
        @extend .sw-btn_dummy;

        height:56px;
        padding:0 24px;

        color:$color-base;
        border-radius:100px;
        background:$color-primary;

        font-size:16px;
        line-height:56px;
        font-weight:500;
        text-align:center;
        text-decoration:none;

        transition:opacity 0.25s;

        &:hover {
            color:$color-base;
            opacity:0.8;
        }

        @media (min-width:$screen-sm-min) {
            height:64px;
            padding:0 28px;

            font-size:18px;
            line-height:64px;
        }

        @media (min-width:$screen-lg-min) {
            height:72px;
            padding:0 33px;

            font-size:20px;
            line-height:72px;
        }

        &-icon {
            width:10px;
            height:10px;
            display:inline-block;
            margin:0 0 0 16px;

            @media (min-width:$screen-sm-min) {
                width:12px;
                height:12px;
            }

            svg {
                width:100%;
                height:100%;
            }
        }
    }

    &_nav {
        @extend .sw-btn_dummy;

        height:56px;
        padding:0 24px;

        color:$color-base;
        border-radius:100px;
        background:$color-primary;

        font-size:16px;
        line-height:56px;
        font-weight:500;
        text-align:center;
        text-decoration:none;

        transition:opacity 0.25s;

        &:hover, &:focus {
            color:$color-base;
            opacity:0.8;
        }

        @media (min-width:$screen-sm-min) {
            height:64px;
            padding:0 28px;

            font-size:18px;
            line-height:64px;
        }

        @media (min-width:$screen-md-min) {
            height:40px;
            padding:0 30px;

            font-size:14px;
            line-height:40px;
        }

        &-icon {
            width:10px;
            height:10px;
            display:inline-block;
            margin:0 0 0 16px;

            @media (min-width:$screen-sm-min) {
                width:12px;
                height:12px;
            }

            svg {
                width:100%;
                height:100%;
            }
        }
    }

    &_menu {
        @extend .sw-btn_dummy;

        width:20px;

        @media (min-width:$screen-sm-min) {
            width:26px;
        }

        &:before {
            content:"";
            position:absolute;
            top:-10px;
            left:-10px;
            right:-10px;
            bottom:-10px;
        }

        span {
            position:relative;
            display:block;
            width:100%;
            height:2px;
            margin:4px 0;
            top:0;

            border-radius:25px;
            background:currentColor;

            transition:top ease-out 0.15s 0.15s, transform ease-out 0.15s 0s;

            @supports (height:1.5px) {
                height:1.5px;
            }

            @media (min-width:$screen-sm-min) {
                margin:5px 0;
            }

            &:nth-child(2) {
                transition:opacity 0s 0.12s;
            }
        }

        &.-active {
            span {
                transition:top ease-out 0.15s, transform ease-out 0.15s 0.15s;
            }

            span:first-child {
                top:5px;
                transform:rotate(45deg);

                @media (min-width:$screen-sm-min) {
                    top:7px;
                }
            }

            span:nth-child(2) {
                opacity:0;
            }

            span:last-child {
                top:-5px;
                transform:rotate(-45deg);

                @supports (height:1.5px) {
                    top:-6px;
                }

                @media (min-width:$screen-sm-min) {
                    top:-7px;

                    @supports (height:1.5px) {
                        top:-6px;
                    }
                }
            }
        }
    }
}
