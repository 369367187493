.sw-offer {
    $root:&;
    position:relative;

    &-content {
        @extend .sw-content;
    }

    &-container {
        @extend .sw-container;
    }

    &-main {
        position:relative;
        height:720px;
        padding-top:80px;

        border-radius:24px;

        overflow:hidden;

        @media (min-width:$screen-sm-min) {
            height:830px;
            padding-top:100px;

            border-radius:32px;
        }

        @media (min-width:$screen-md-min) {
            display:flex;
            justify-content:center;
            flex-direction:column;
            height:445px;
            padding-top:0;

            border-radius:40px;
        }

        @media (min-width:$screen-lg-min) {
            height:500px;
        }

        @media (min-width:$screen-xl-min) {
            height:678px;
        }
    }

    &-video {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;

        border-radius:24px;

        overflow:hidden;

        @media (min-width:$screen-sm-min) {
            border-radius:32px;
        }

        @media (min-width:$screen-md-min) {
            border-radius:40px;
        }

        img, video {
            width:100%;
            height:100%;

            object-fit:cover;
        }
    }

    &-body {
        position:relative;
        margin:0 auto;
        width:70%;

        text-align:center;

        @media (min-width:$screen-sm-min) {
            width:506px;
        }

        @media (min-width:$screen-md-min) {
            width:620px;
        }

        @media (min-width:$screen-lg-min) {
            width:658px;
        }

        @media (min-width:$screen-xl-min) {
            width:834px;
        }
    }

    &-caption {
        margin-bottom:16px;

        font-size:16px;
        line-height:130%;
        letter-spacing:-1px;

        color:$color-primary;

        overflow:hidden;

        @media (min-width:$screen-sm-min) {
            margin-bottom:20px;

            font-size:20px;
        }

        @media (min-width:$screen-md-min) {
            margin-bottom:24px;
        }

        @media (min-width:$screen-lg-min) {
            margin-bottom:28px;

            font-size:24px;
        }

        span {
            display:inline-block;
        }
    }

    &-header {
        font-size:28px;
        line-height:120%;
        letter-spacing:-1px;
        font-weight:600;

        overflow:hidden;

        @media (min-width:$screen-sm-min) {
            font-size:32px;
        }

        @media (min-width:$screen-md-min) {
            font-size:36px;
        }

        @media (min-width:$screen-lg-min) {
            font-size:40px;
        }

        @media (min-width:$screen-xl-min) {
            font-size:50px;
        }
    }

    &-action {
        display:inline-block;
        margin-top:32px;

        overflow:hidden;

        transition:opacity 0.25s;

        &:hover {
            opacity:0.8;
        }

        @media (min-width:$screen-lg-min) {
            margin-top:40px;
        }
    }
}
