.sw-hero {
    $root:&;
    position:relative;

    &-content {
        @extend .sw-content;

        padding-top:75px;

        @media (min-width:$screen-sm-min) {
            padding-top:105px;
        }

        @media (min-width:$screen-md-min) {
            padding-top:115px;
        }

        @media (min-width:$screen-lg-min) {
            padding-top:125px;
        }
    }

    &-container {
        @extend .sw-container;
    }

    &-main {
        position:relative;
        height:764px;
        padding-top:80px;

        border-radius:24px;

        overflow:hidden;

        @media (min-width:$screen-sm-min) {
            height:968px;
            padding-top:120px;

            border-radius:32px;
        }

        @media (min-width:$screen-md-min) {
            display:flex;
            justify-content:center;
            flex-direction:column;
            height:550px;
            padding-top:0;

            border-radius:40px;
        }

        @media (min-width:$screen-lg-min) {
            height:560px;
        }

        @media (min-width:$screen-xl-min) {
            height:678px;
        }
    }

    &-video {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;

        border-radius:24px;

        overflow:hidden;

        @media (min-width:$screen-sm-min) {
            border-radius:32px;
        }

        @media (min-width:$screen-md-min) {
            border-radius:40px;
        }

        img, video {
            width:100%;
            height:100%;

            object-fit:cover;
        }
    }

    &-body {
        padding:0 20px;

        text-align:center;

        @media (min-width:$screen-sm-min) {
            padding:0 60px;
        }

        @media (min-width:$screen-md-min) {
            padding:0 80px;
            max-width:505px;

            text-align:left;
        }

        @media (min-width:$screen-lg-min) {
            padding:0 90px;
            max-width:590px;
        }

        @media (min-width:$screen-xl-min) {
            padding:0 80px;
            max-width:100%;
        }
    }

    &-header {
        position:relative;
        margin-bottom:8px;

        font-size:40px;
        line-height:110%;
        letter-spacing:-1px;
        font-weight:600;

        overflow:hidden;

        @media (min-width:$screen-sm-min) {
            font-size:48px;
            letter-spacing:-3px;
        }

        @media (min-width:$screen-md-min) {
            font-size:52px;
        }

        @media (min-width:$screen-lg-min) {
            font-size:62px;
        }

        @media (min-width:$screen-xl-min) {
            font-size:72px;
        }
    }

    &-text {
        position:relative;
        max-width:70%;
        margin:0 auto;

        font-size:18px;
        line-height:140%;
        font-weight:500;

        opacity:0.8;

        overflow:hidden;

        @media (min-width:$screen-sm-min) {
            max-width:332px;
        }

        @media (min-width:$screen-md-min) {
            max-width:430px;
            margin:0;

            font-size:20px;
        }

        @media (min-width:$screen-lg-min) {
            max-width:492px;

            font-size:24px;
        }
    }

    &-action {
        display:inline-block;
        position:relative;
        margin:32px 0 0 0;

        overflow:hidden;

        transition:opacity 0.25s;

        &:hover {
            opacity:0.8;
        }

        @media (min-width:$screen-lg-min) {
            margin:36px 0 0 0;
        }
    }
}
