.sw-advantage {
    $root:&;
    position:relative;

    &-content {
        @extend .sw-content;

        padding-top:32px;

        @media (min-width:$screen-lg-min) {
            padding-top:40px;
        }
    }

    &-container {
        @extend .sw-container;
    }

    &-items {

        @media (min-width:$screen-sm-min) {
            display:grid;
            grid-template-columns:repeat(2, 1fr);
            gap:20px;
        }

        @media (min-width:$screen-md-min) {
            grid-template-columns:repeat(4, 1fr);
        }
    }

    &-item {
        display:flex;
        align-items:center;
        height:100px;
        padding:0 30px;
        margin:0 0 12px 0;

        background:$color-base;
        border-radius:20px;
        box-shadow:0px 44px 24px rgba(237, 239, 241, 0.15);

        &:last-child {
            margin:0;
        }

        @media (min-width:$screen-sm-min) {
            height:118px;
            margin:0;
        }

        @media (min-width:$screen-md-min) {
            height:96px;
            padding:0;
            justify-content:center;
        }

        @media (min-width:$screen-lg-min) {
            height:104px;

            border-radius:24px;
        }

        @media (min-width:$screen-xl-min) {
            height:156px;

            border-radius:30px;
        }
    }

    &-icon {
        display:flex;
        align-items:center;
        justify-content:center;
        width:52px;
        height:52px;
        margin-right:18px;

        border-radius:50%;
        border:1px solid $color-muted;
        color:$color-primary;

        @media (min-width:$screen-sm-min) {
            width:56px;
            height:56px;
        }

        @media (min-width:$screen-lg-min) {
            width:64px;
            height:64px;
        }

        @media (min-width:$screen-xl-min) {
            width:68px;
            height:68px;
        }

        svg {
            transform:scale(0.74);

            @media (min-width:$screen-sm-min) {
                transform:scale(0.8);
            }

            @media (min-width:$screen-lg-min) {
                transform:scale(1);
            }

            #{$root}-item:nth-child(1) & {
                width:22px;
                height:24px;
            }

            #{$root}-item:nth-child(2) & {
                width:18px;
                height:18px;
            }

            #{$root}-item:nth-child(3) & {
                width:31px;
                height:20px;
            }

            #{$root}-item:nth-child(4) & {
                width:26px;
                height:26px;
            }
        }
    }

    &-title {
        font-size:14px;
        line-height:130%;
        font-weight:500;

        @media (min-width:$screen-sm-min) {
            font-size:16px;
        }

        @media (min-width:$screen-md-min) {
            font-size:14px;
        }

        @media (min-width:$screen-lg-min) {
            font-size:16px;
        }

        @media (min-width:$screen-xl-min) {
            font-size:18px;
        }
    }

    &-caption {
        font-size:20px;
        line-height:130%;
        letter-spacing:-1px;
        font-weight:600;

        @media (min-width:$screen-sm-min) {
            font-size:26px;
            letter-spacing:-2px;
        }

        @media (min-width:$screen-md-min) {
            font-size:24px;
        }

        @media (min-width:$screen-lg-min) {
            font-size:28px;
        }

        @media (min-width:$screen-xl-min) {
            font-size:32px;
        }
    }
}
