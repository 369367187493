.sw-footer {
    $root:&;
    position:relative;

    overflow:hidden;

    &-content {
        @extend .sw-content;
        padding-bottom:24px;

        @media (min-width:$screen-sm-min) {
            padding-bottom:26px;
        }
    }

    &-container {
        @extend .sw-container;

        @media (max-width:$screen-xs-max) {
            padding:0 45px;
        }
    }

    &-row {

        &:first-child {
            padding-bottom:40px;

            @media (min-width:$screen-sm-min) {
                padding-bottom:63px;
            }

            @media (min-width:$screen-md-min) {
                padding-bottom:48px;
            }
        }

        &:last-child {
            padding-top:24px;

            border-top:1px solid rgba($color-text, 0.1);

            @media (min-width:$screen-sm-min) {
                display:flex;
                justify-content:space-between;
                padding-top:26px;
            }
        }
    }

    &-grid {
        display:grid;
        grid-template-areas:
                "logo"
                "ngrid"
                "social";

        @media (min-width:$screen-sm-min) {
            grid-template-columns:1fr auto;
            grid-template-areas:
                "logo ngrid"
                "social ngrid";
        }
    }

    &-ngrid {
        display:flex;
        flex-wrap:wrap;
        grid-area:ngrid;
        margin:0 -23px;

        @media (min-width:$screen-sm-min) {
            margin:0 -28px;
            flex-wrap:nowrap;
        }

        @media (min-width:$screen-md-min) {
            margin:0 -51px;
        }

        @media (min-width:$screen-lg-min) {
            margin:0 -58px;
        }

        @media (min-width:$screen-xl-min) {
            margin:0 -82px;
        }

        &-col {
            margin-bottom:40px;
            padding:0 23px;

            &:last-child {
                margin-bottom:0;
            }

            @media (min-width:$screen-sm-min) {
                padding:0 28px;
                margin-bottom:0;
            }

            @media (min-width:$screen-md-min) {
                padding:0 51px;
            }

            @media (min-width:$screen-lg-min) {
                padding:0 58px;
            }

            @media (min-width:$screen-xl-min) {
                padding:0 82px;
            }
        }
    }

    &-logo {
        display:inline-block;
        width:160px;
        height:34px;
        grid-area:logo;
        margin:0 0 48px 0;

        text-decoration:none;

        transition:opacity 0.25s;

        &:hover {
            opacity:0.8;
        }

        @media (min-width:$screen-sm-min) {
            width:120px;
            height:25px;
            margin:0;
        }

        @media (min-width:$screen-lg-min) {
            width:131px;
            height:28px;
        }

        img {
            width:100%;
            height:100%;
        }
    }

    &-social {
        display:flex;
        grid-area:social;
        margin-top:48px;

        @media (min-width:$screen-sm-min) {
            display:inline-block;
            margin-top:auto;
        }
    }

    &-title {
        margin-bottom:24px;

        font-size:16px;
        line-height:120%;
        font-weight:600;

        @media (min-width:$screen-sm-min) {
            margin-bottom:32px;
        }

        @media (min-width:$screen-md-min) {
            font-size:18px;
        }

        @media (min-width:$screen-lg-min) {
            margin-bottom:36px;

            font-size:20px;
        }
    }

    &-navs {
        margin:-8px 0;

        @media (min-width:$screen-sm-min) {
            margin:-10px 0;
        }
    }

    &-nav {

        a {
            display:inline-block;
            padding:8px 0;

            font-size:16px;
            line-height:120%;
            font-weight:500;
            text-decoration:none;

            opacity:0.6;

            transition:color 0.25s, opacity 0.25s;

            @media (min-width:$screen-sm-min) {
                padding:10px 0;
            }

            &:hover {
                opacity:1;
                color:$color-primary;
            }
        }
    }

    &-copyright {
        margin:12px 0 0 0;

        font-size:16px;
        line-height:120%;
        font-weight:500;
        text-align:center;

        &:first-child {
            margin:0;
        }

        @media (min-width:$screen-sm-min) {
            margin:0;

            font-weight:500;
            text-align:left;
        }

        span {
            opacity:0.2;
        }

        a {
            text-decoration:none;

            opacity:0.2;
            transition:opacity 0.6s;

            &:hover {
                opacity:1;
            }
        }
    }

    &-brand {
        display:inline-block;
        margin-top:16px;
        width:67px;
        height:19px;

        text-decoration:none;
        transition:opacity 0.25s;

        &:hover {
            opacity:0.7;
        }

        @media (min-width:$screen-sm-min) {
            margin-top:0;
            width:84px;
            height:24px;
        }

        img {
            width:100%;
            height:100%;
        }
    }
}

