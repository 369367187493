.sw-background {
    background-image:url("../../../public/img/bg/bg-sm.jpg");
    background-image:url("../../../public/img/bg/bg-sm.webp");
    background-repeat:no-repeat;
    background-position:top center;
    background-size:cover;

    backface-visibility:hidden;
    transform:translateZ(0);

    @media (min-width:$screen-sm-min) {
        background-image:url("../../../public/img/bg/bg-md.jpg");
        background-image:url("../../../public/img/bg/bg-md.webp");
        background-size:auto;
    }

    @media (min-width:$screen-xl-min) {
        @media (-webkit-min-device-pixel-ratio:2), (min-resolution:192dpi) {
            background-image:url("../../../public/img/bg/bg.jpg");
            background-image:url("../../../public/img/bg/bg.webp");
        }
    }

    @media (min-width:$screen-ul-min) {
        background-size:cover;
    }
}
