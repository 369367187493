.sw-brand {
    $root:&;
    position:relative;

    overflow:hidden;

    &-content {
        @extend .sw-content;
    }

    &-container {
        @extend .sw-container;
    }

    &-header {
        font-size:28px;
        line-height:120%;
        letter-spacing:-1px;
        font-weight:600;
        text-align:center;

        overflow:hidden;

        @media (min-width:$screen-sm-min) {
            font-size:32px;
            letter-spacing:-2px;
        }

        @media (min-width:$screen-md-min) {
            font-size:36px;
        }

        @media (min-width:$screen-lg-min) {
            font-size:40px;
        }

        @media (min-width:$screen-xl-min) {
            font-size:50px;
        }
    }

    &-reels {
        margin:28px 0 0 0;

        @media (min-width:$screen-sm-min) {
            margin:36px 0 0 0;
        }

        @media (min-width:$screen-md-min) {
            margin:54px 0 0 0;
        }

        @media (min-width:$screen-xl-min) {
            margin:60px 0 0 0;
        }
    }

    &-reel {
        margin:0 0 12px 0;

        white-space:nowrap;

        @media (min-width:$screen-sm-min) {
            margin:0 0 20px 0;
        }

        &:last-child {
            margin:0;
        }

        &-items {
            display:flex;
            align-items:center;
        }

        &-item {
            position:relative;
            display:flex;
            align-items:center;
            justify-content:center;
            width:240px;
            height:100px;

            flex-shrink:0;

            &:after {
                content:'';
                position:absolute;
                top:0;
                right:6px;
                bottom:0;
                left:6px;

                background:$color-base;
                border-radius:20px;

                @media (min-width:$screen-sm-min) {
                    right:10px;
                    left:10px;
                }
            }

            @media (min-width:$screen-sm-min) {
                width:210px;
                height:90px;
            }

            @media (min-width:$screen-md-min) {
                width:280px;
                height:116px;
            }

            @media (min-width:$screen-lg-min) {
                width:300px;
                height:134px;

                border-radius:24px;
            }

            @media (min-width:$screen-xl-min) {
                width:345px;
                height:156px;

                border-radius:30px;
            }

            img {
                position:relative;
                height:56px;

                z-index:1;

                @media (min-width:$screen-sm-min) {
                    height:47px;
                }

                @media (min-width:$screen-md-min) {
                    height:58px;
                }

                @media (min-width:$screen-lg-min) {
                    height:70px;
                }

                @media (min-width:$screen-xl-min) {
                    height:84px;
                }
            }
        }
    }
}
