.sw-navbar {
    $root:&;

    &-strip {
        position:absolute;
        top:32px;
        left:0;
        right:0;
        z-index:100;

        transition:color 0.3s;

        #{$root}.-inverse & {
            color:$color-base;
        }
    }

    &-container {
        @extend .sw-container;
    }

    &-fill {
        position:fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index:99;
        opacity:0;
        max-height:0;
        overflow:hidden;

        background-image:url("../../../public/img/bg/bg-menu.jpg");
        background-image:url("../../../public/img/bg/bg-menu.webp");
        background-position:center center;
        background-size:cover;

        pointer-events:none;
        transition:max-height 0s 0.4s, opacity 0.35s;

        @media (min-width:$screen-md-min) {
            display:none;
        }

        #{$root}.-open & {
            max-height:100%;
            transition-delay:0s;
            pointer-events:auto;
            opacity:1;
        }
    }

    &-grid {
        display:flex;
        align-items:center;
        justify-content:space-between;

        @media (min-width:$screen-md-min) {
            justify-content:flex-start;
        }

        &-col {

            &.-center {

                @media (min-width:$screen-md-min) {
                    margin-left:75px;
                }
            }

            &.-right {

                @media (min-width:$screen-md-min) {
                    margin-left:auto;
                }
            }
        }
    }

    &-logo {
        display:inline-block;
        width:112px;
        height:27px;

        text-decoration:none;

        transition:opacity 0.25s;

        &:hover {
            opacity:0.8;
        }

        @media (min-width:$screen-lg-min) {
            width:124px;
            height:30px;
        }

        img {
            width:100%;
            height:100%;
        }
    }

    &-navs {

        @media (max-width:$screen-sm-max) {
            position:fixed;
            top:50%;
            left:40px;

            opacity:0;
            transform:translateY(-50%);
            visibility:hidden;
            pointer-events:none;
            margin-top:-20px;

            transition:visibility 0s 0s, opacity 0.35s;

            #{$root}.-open & {
                opacity:1;
                visibility:visible;
                pointer-events:auto;
            }
        }

        @media (min-width:$screen-sm-min) {
            margin:-16px 0;
            margin-top:-40px;
            left:150px;
        }

        @media (min-width:$screen-md-min) {
            position:relative;
            margin:0 -13px;
            left:auto;
        }

        &:after {
            content:'';
            position:absolute;
            top:0;
            bottom:0;
            left:-22px;
            margin:auto;
            height:100%;
            width:1px;
            display:none;

            background:#B4BAC7;

            @media (min-width:$screen-md-min) {
                display:block;
            }
        }
    }

    &-nav {
        display:block;
        padding:8px 0;

        font-size:28px;
        letter-spacing:-1px;
        font-weight:600;
        text-decoration:none;

        transition:color 0.25s, opacity 0.25s;

        &:hover {
            opacity:0.7;
        }

        @media (max-width:$screen-sm-max) {
            overflow:hidden;

            @for $i from 1 through 5 {
                &:nth-child(#{$i}) span {
                    transition-delay:0.05s + $i * 0.05s;
                }
            }
        }

        @media (min-width:$screen-sm-min) {
            padding:16px 0;

            font-size:32px;
        }

        @media (min-width:$screen-md-min) {
            display:inline-block;
            padding:0 13px;

            font-size:14px;
            font-weight:500;

            color:$color-smooth;

            &:hover {
                opacity:1;
                color:$color-primary;
            }
        }


        @media (min-width:$screen-lg-min) {
            padding:0 20px;

            font-size:16px;
        }

        @media (min-width:$screen-xl-min) {
            padding:0 25px;
        }

        &.-action {
            display:flex;
            flex-direction:row-reverse;

            @media (min-width:$screen-md-min) {
                display:inline-flex;
                align-items:center;
                flex-direction:row;
                padding:0 16px;
                margin:0 0 0 15px;
                height:48px;

                border:1px solid $color-muted;
                border-radius:100px;
            }

            @media (min-width:$screen-lg-min) {
                height:56px;
                padding:0 23px;
                margin:0 0 0 20px;
            }

            @media (min-width:$screen-xl-min) {
                margin:0 0 0 25px;
            }
        }

        span {
            position:relative;
            display:inline-block;
            vertical-align:middle;

            @media (max-width:$screen-sm-max) {
                transform:translateY(125%);
                transition:transform cubic-bezier(0.16, 1, 0.3, 1) 0.8s;

                #{$root}.-open & {
                    transform:translateY(0) translateZ(0);
                }
            }

            svg {
                width:24px;
                height:24px;
                margin:-2px 0 0 16px;

                color:$color-primary;

                @media (min-width:$screen-sm-min) {
                    margin:-1px 0 0 16px;
                }

                @media (min-width:$screen-md-min) {
                    margin:0 10px 0 0;
                }
            }
        }

    }

    &-action {

        @media (max-width:$screen-sm-max) {
            position:fixed;
            bottom:60px;
            left:40px;

            opacity:0;
            transform:translateY(20px);
            visibility:hidden;
            pointer-events:none;

            overflow:hidden;

            transition:visibility 0s 0s;

            a {
                @media (max-width:$screen-sm-max) {
                    transform:translateY(110%);
                    transition:transform 0.8s cubic-bezier(0.16, 1, 0.3, 1) 0.4s;

                    #{$root}.-open & {
                        transform:translateY(0) translateZ(0);
                    }
                }
            }

            #{$root}.-open & {
                opacity:1;
                visibility:visible;
                pointer-events:auto;
            }
        }

        @media (min-width:$screen-sm-min) {
            bottom:90px;
            left:150px;
        }

        @media (min-width:$screen-md-min) {
            display:inline-block;
            bottom:auto;
            left:auto;
        }

        #{$root}.-open & {
            display:inline-block;
        }
    }

    &-toggle {
        display:inline-block;
        vertical-align:middle;

        @media (min-width:$screen-md-min) {
            display:none;
        }

        #{$root}.-open & button {
            @extend .-active;
        }
    }
}
