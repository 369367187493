.sw-feature {
    $root:&;
    position:relative;

    &-content {
        @extend .sw-content;

        @media (min-width:$screen-xl-min) {
            padding-bottom:120px;
        }
    }

    &-container {
        @extend .sw-container;
    }

    &-caption {
        margin-bottom:16px;

        font-size:16px;
        line-height:130%;
        letter-spacing:-1px;
        text-align:center;

        color:$color-primary;
        overflow:hidden;

        @media (min-width:$screen-sm-min) {
            margin-bottom:20px;

            font-size:20px;
        }

        @media (min-width:$screen-md-min) {
            margin-bottom:24px;
        }

        @media (min-width:$screen-lg-min) {
            margin-bottom:30px;

            font-size:24px;
        }

        span {
            display:inline-block;
        }
    }

    &-header {
        max-width:580px;
        margin:0 auto;

        font-size:28px;
        line-height:120%;
        letter-spacing:-1px;
        font-weight:600;
        text-align:center;

        overflow:hidden;

        @media (min-width:$screen-sm-min) {
            max-width:610px;

            font-size:32px;
            letter-spacing:-2px;
        }

        @media (min-width:$screen-md-min) {
            max-width:714px;

            font-size:36px;
        }

        @media (min-width:$screen-lg-min) {
            max-width:800px;

            font-size:40px;
        }

        @media (min-width:$screen-xl-min) {
            max-width:976px;

            font-size:50px;
        }
    }

    &-items {
        margin-top:40px;

        @media (min-width:$screen-sm-min) {
            display:grid;
            grid-template-columns:repeat(2, 1fr);
            gap:20px;
            margin-top:80px;
        }

        @media (min-width:$screen-md-min) {
            margin-top:90px;
        }

        @media (min-width:$screen-xl-min) {
            margin-top:100px;
        }
    }

    &-item {
        position:relative;
        height:480px;
        margin:0 0 12px 0;
        padding:40px 32px;

        border-radius:24px;

        overflow:hidden;

        &:nth-child(1) {
            background:#262626;
            color:$color-base;

            a {
                background: white;
                color: #262626;
            }
        }

        &:nth-child(2) {
            background:#DFE4F9;

            @media (min-width:$screen-sm-min) {
                margin-top:170px;
            }

            @media (min-width:$screen-md-min) {
                margin-top:160px;
            }

            @media (min-width:$screen-lg-min) {
                margin-top:175px;
            }

            @media (min-width:$screen-xl-min) {
                margin-top:190px;
            }
        }

        &:nth-child(3) {
            background:$color-primary;
            color:$color-base;

            @media (min-width:$screen-sm-min) {
                margin-top:-170px;
            }

            @media (min-width:$screen-md-min) {
                margin-top:-160px;
            }

            @media (min-width:$screen-lg-min) {
                margin-top:-175px;
            }

            @media (min-width:$screen-xl-min) {
                margin-top:-190px;
            }
            
            a {
                background: white;
                color: #262626;
            }
        }

        &:nth-child(4) {
            background:#F3F2F5;
        }

        &:nth-child(5) {
            display:flex;
            justify-content:center;
            flex-direction:column;

            background:rgba($color-base, 0.1);
            border:1px solid #E1E1E5;

            @media (min-width:$screen-sm-min) {
                margin:0;
                margin-top:-170px;
            }

            @media (min-width:$screen-md-min) {
                margin-top:-160px;
            }

            @media (min-width:$screen-lg-min) {
                margin-top:-175px;
            }

            @media (min-width:$screen-xl-min) {
                margin-top:-190px;
            }
        }

        @media (min-width:$screen-sm-min) {
            height:550px;
            margin:0;
            padding:48px 36px;

            border-radius:40px;
        }

        @media (min-width:$screen-md-min) {
            height:600px;
        }

        @media (min-width:$screen-lg-min) {
            height:640px;
            padding:56px 50px;
        }

        @media (min-width:$screen-xl-min) {
            padding:70px 60px;
        }
    }

    &-title {
        margin:0 0 16px 0;
        max-width:180px;

        font-size:28px;
        line-height:130%;
        letter-spacing:-1px;
        font-weight:600;

        @media (min-width:$screen-sm-min) {
            margin:0 0 20px 0;
            max-width:205px;

            font-size:32px;
            letter-spacing:-2px;
        }

        @media (min-width:$screen-md-min) {
            max-width:235px;

            font-size:36px;
        }

        @media (min-width:$screen-lg-min) {
            max-width:265px;
            margin:0 0 24px 0;

            font-size:40px;
        }

        @media (min-width:$screen-xl-min) {
            max-width:340px;

            font-size:50px;
        }
    }

    &-subtitle {
        margin:0 0 12px 0;

        font-size:16px;
        line-height:130%;
        font-weight:600;

        @media (min-width:$screen-sm-min) {
            margin:0 0 16px 0;

            font-size:18px;
        }

        @media (min-width:$screen-lg-min) {
            font-size:20px;
        }
    }

    &-text {
        font-size:13px;
        line-height:140%;

        opacity:0.9;

        @media (min-width:$screen-sm-min) {
            font-size:14px;
        }

        @media (min-width:$screen-lg-min) {
            max-width:480px;

            font-size:20px;
        }
    }


    &-img {
        position:absolute;
        bottom:0;
        right:0;
        width:200px;
        height:auto;

        @media (min-width:$screen-sm-min) {
            width:220px;
        }

        @media (min-width:$screen-md-min) {
            width:260px;
        }

        img {
            width:100%;
            height:100%;
        }
    }

    &-icon {
        width:26px;
        height:22px;
        margin:0 0 40px 0;

        color:$color-primary;

        svg {
            width:100%;
            height:100%;
        }
    }

    &-action {
        margin:28px 0 0 0;

        @media (min-width:$screen-lg-min) {
            margin:30px 0 0 0;
        }

        @media (min-width:$screen-xl-min) {
            margin:35px 0 0 0;
        }
    }
}
