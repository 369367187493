.sw-social {
    $root:&;
    display:flex;
    margin:0 -8px;

    @media (min-width:$screen-md-min) {
        margin:0 -9px;
    }

    &-item {
        display:flex;
        align-items:center;
        justify-content:center;
        width:48px;
        height:48px;
        margin:0 8px;

        border-radius:50%;
        background:#E4E6F0;

        text-decoration:none;

        transition:opacity 0.25s, transform cubic-bezier(0.16, 1, 0.3, 1) 1s;

        &:hover {
            opacity:0.85;
            transform:scale(0.96);
        }

        @media (min-width:$screen-md-min) {
            width:56px;
            height:56px;
        }

        svg {
            width:20px;
            height:16px;

            @media (min-width:$screen-md-min) {
                width:24px;
                height:20px;
            }
        }
    }
}
